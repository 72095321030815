<template>
  <section id="about" title="About What The Hack 2022">
    <SectionHeader>What The Hack?</SectionHeader>
    <div class="about-content">
      <div class="about-content-main">
        <Para>
          SUTD's premier hackathon returns for its 4th iteration! <span>What The Hack</span> is a hardware and software
          hackathon, where engineers, creators and designers collaborate to make innovative solutions to meaningful
          real-world problems. <span>All in 2 Days.</span>
        </Para>
        <Para>
          This year’s theme is <span>A Brighter Future</span>, of which there will be three problem themes related to
          this overarching theme - <span>Sustainable & Smart Cities</span>,
          <span>Healthcare & Accessibility Technology</span> and <span>Social Activities & Games</span>.
        </Para>
      </div>
      <div class="about-content-aside">
        <div class="aside-block">
          <div class="aside-number">3 - 5</div>
          <div class="aside-refer">Pax / Group</div>
        </div>
        <div class="aside-block">
          <div class="aside-number">2</div>
          <div class="aside-refer">Days</div>
        </div>
        <div class="aside-block">
          <div class="aside-number">Lots</div>
          <div class="aside-refer">Of Code & Fun!</div>
        </div>
      </div>
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" id="factoryscape" viewBox="0 0 3093.3 1185.5">
      <g class="base">
        <path
          fill="#79c89f"
          d="M0 961l103 11c103 10 309 32 516 21s412-53 618-48 413 59 619 96 412 59 619 70c206 10 412 10 515 10h103v64H0z"
        ></path>
        <ellipse cx="2428.6" cy="929.7" fill="#d65c85" rx="39.5" ry="27.9"></ellipse>
        <path fill="#d65c85" d="M2389 806h79v124h-79z"></path>
        <ellipse cx="2428.6" cy="805.7" fill="#c36" rx="39.5" ry="27.9"></ellipse>
        <path fill="#d65c85" d="M2372 936h-55v-94l55 33v61zM2261 936h-55v-94l55 33v61z"></path>
        <path fill="#ebadc2" d="M2372 875v61l-111-61v61l-111-61v253h333V936l-111-61z"></path>
        <path fill="#d65c85" d="M2150 1128l-71-48-1-248 72 43v253z"></path>
        <rect width="131.2" height="19" x="2175.4" y="982.4" fill="#d65c85" rx="6.8"></rect>
        <rect width="131.2" height="19" x="2325.9" y="982.4" fill="#d65c85" rx="6.8"></rect>
        <rect width="131.2" height="19" x="2176" y="1015" fill="#d65c85" rx="6.8"></rect>
        <rect width="131.2" height="19" x="2326.5" y="1015" fill="#d65c85" rx="6.8"></rect>
        <path fill="#404fcf" d="M2629 1134h-160l22-387h111l27 387z"></path>
        <ellipse cx="2546.3" cy="747.4" fill="#1023c3" rx="55.5" ry="28.9"></ellipse>
        <path fill="#404fcf" d="M2810 1134h-160l21-387h111l28 387z"></path>
        <ellipse cx="2726.9" cy="747.4" fill="#1023c3" rx="55.5" ry="28.9"></ellipse>
        <path fill="#404fcf" d="M2988 1134h-160l21-387h111l28 387z"></path>
        <ellipse cx="2904.7" cy="747.4" fill="#1023c3" rx="55.5" ry="28.9"></ellipse>
        <path
          fill="#79c89f"
          d="M117 949a27 27 0 00-3 0c-19 2-24-9-25-25-1-10-2-21 1-31v-8c4-25 8-50 16-74 2-7 4-13 9-19s8-6 14 0c4 6 7 13 9 20 5 16 8 33 11 50l2 13 3 31a70 70 0 01-3 32c-3 8-9 11-17 11h-10c-3-4-2-9-2-14l-1-40c-2 14-1 27-1 40-1 5 0 10-3 14z"
        ></path>
        <path fill="#8192d9" d="M117 949v-53c0-3-1-9 4-9 5 1 3 6 3 9v108c0 3 2 9-4 8-4 0-3-5-3-8v-55z"></path>
        <path
          fill="#f4c6a6"
          d="M2407 1146l-13-1c-8-2-11-7-13-14-4-14-1-27 3-40v-3l7-10c10-12 27-13 38-1a25 25 0 015 8 14 14 0 002 1c3 12 6 23 5 36a49 49 0 01-1 5c-2 14-8 19-23 19h-4c-4-4-2-9-3-14-1 5 1 10-3 14z"
        ></path>
        <path fill="#818fdb" d="M2407 1146v-16c0-2 0-4 3-4 2 0 2 2 3 4v38c0 2 0 4-3 4s-3-2-3-4v-22z"></path>
        <path
          fill="#f4d3bd"
          d="M1977 1058c-1-16 2-33 4-49 4-10 6-20 11-30a75 75 0 015-9c6-10 10-10 19-2 7 14 13 29 15 45l2 2c3 4 3 9 2 13v22c0 15-6 21-20 21h-6c-4-4-3-9-3-14v-26c-2 12 4 26-4 40h-5c-10 1-16-3-20-13z"
        ></path>
        <path
          fill="#828fdc"
          d="M2002 1071l1-33a70 70 0 010-7 3 3 0 013-3c2-1 3 1 3 3v73a43 43 0 010 8 2 2 0 01-3 2v-1l-3-1a49 49 0 010-8v-33z"
        ></path>
        <path
          fill="#b8e2cc"
          d="M188 974c-2-21 3-42 6-63 4-12 7-25 12-37a92 92 0 017-12c8-13 12-13 24-2 10 18 16 36 19 56l2 4c5 4 4 10 3 16v28c0 19-7 26-25 26h-7c-5-5-4-12-4-18l-1-33c-2 15 6 34-4 51h-7c-12 1-20-5-25-16z"
        ></path>
        <path
          fill="#828fdc"
          d="M220 990l1-42a86 86 0 010-9 4 4 0 013-4c3 0 4 2 4 4l1 15v78a53 53 0 01-1 9 3 3 0 01-4 2l-3-2a62 62 0 010-9l-1-42z"
        ></path>
        <path
          fill="#fff"
          d="M221 1003c-5 12-7 13-20 14h-10c-6-8-2-17-3-25-2 8 3 17-5 24h-10c-13 0-18-4-18-16-1-14 4-28 9-42 3-7 6-14 11-20 9-10 18-10 26 1 10 12 14 27 17 42a89 89 0 013 22z"
        ></path>
        <path fill="#8490dd" d="M183 1016l1-28c0-2-1-5 3-6 3 0 4 3 4 5v57c0 4 0 8-4 10-3-4-3-9-3-13v-18l-1-7z"></path>
        <path
          fill="#79c89f"
          d="M305 991l-16-1c-9-2-13-9-16-17-4-17-1-32 4-48l1-4 8-12c12-15 32-16 45-2a31 31 0 016 10 16 16 0 003 2c4 14 7 28 6 43a62 62 0 010 6c-4 17-11 23-29 24h-5c-5-5-2-12-3-17-2 5 1 12-4 16z"
        ></path>
        <path fill="#818fdb" d="M305 991v-19c0-3 0-5 3-5s4 2 4 5v46c0 2 0 5-4 5-3 0-3-3-3-5v-27z"></path>
        <path
          fill="#bee5ce"
          d="M441 1007a15 15 0 00-2 0c-11 1-14-6-14-15-1-5-1-11 1-17v-5c2-14 4-29 9-43 1-3 2-7 5-10 3-4 5-4 8 0l5 11c3 10 5 19 6 29l2 7 1 18a40 40 0 01-2 19c-1 4-4 6-9 6h-6c-2-2-1-5-1-8l-1-23v23c0 3 0 6-2 8z"
        ></path>
        <path fill="#8192d9" d="M441 1007v-31c0-2-1-5 2-5 3 1 2 4 2 5v62c0 2 1 5-2 5s-2-3-2-5v-31z"></path>
        <path
          fill="#79c89f"
          d="M520 970h-3c-17 2-22-9-23-23 0-9-1-18 1-28l1-7c3-23 6-45 14-67 2-6 4-12 8-17 5-5 8-5 12 0 5 5 7 12 9 18 5 15 7 30 10 46l2 11 3 28a63 63 0 01-4 29c-2 7-7 10-15 10h-8c-4-4-3-8-3-12l-1-36-1 36c0 4 1 8-2 12z"
        ></path>
        <path fill="#8192d9" d="M520 970v-48c0-3-1-8 4-8 4 0 2 5 2 8l1 48-1 50c0 3 2 8-3 8-4-1-3-5-3-8v-50z"></path>
        <path
          fill="#b8e2cc"
          d="M570 962c-1-17 2-33 5-49 3-9 5-20 10-29a73 73 0 015-9c6-10 9-10 18-2 8 14 13 28 15 44l2 3c3 3 3 8 2 12v22c0 15-5 20-20 21h-5c-4-4-3-9-3-14v-26c-2 12 4 26-4 40h-5c-10 0-16-4-20-13z"
        ></path>
        <path
          fill="#828fdc"
          d="M595 975l1-33a66 66 0 010-7 3 3 0 012-3c2 0 3 1 4 3v72a43 43 0 01-1 7 2 2 0 01-2 2h-1l-2-2a48 48 0 010-7l-1-33z"
        ></path>
        <path
          fill="#fff"
          d="M599 989c-7 17-9 19-28 20h-14c-8-11-2-24-5-35-3 10 5 24-6 34h-15c-18 0-25-6-25-22-1-21 5-41 13-61 4-9 8-19 15-27 12-15 25-15 37 0 14 18 19 40 24 61a126 126 0 014 30z"
        ></path>
        <path fill="#8490dd" d="M546 1008l1-40c0-3-1-7 3-8 6-1 7 3 7 7v81c0 5 0 10-6 13-5-5-4-12-4-18l-1-26v-9z"></path>
        <path
          fill="#fff"
          d="M825 990c-5 12-6 13-19 13l-10 1c-6-8-2-17-3-25-3 8 3 17-5 24h-10c-13 0-17-4-18-15 0-15 4-29 9-42 3-7 6-14 11-19 8-11 17-11 25 0 10 12 14 27 17 42a88 88 0 013 21z"
        ></path>
        <path fill="#8490dd" d="M788 1003l1-28c0-2-1-5 2-5 4-1 5 2 5 5v56c0 3 0 7-4 9-4-4-3-8-3-12l-1-18v-7z"></path>
        <path
          fill="#fff"
          d="M1021 1082a15 15 0 00-2 0c-11 1-14-5-14-14-1-6-1-12 1-18v-5c2-14 4-28 9-42 1-4 2-8 5-11s5-3 8 0a199 199 0 0112 40l1 8 2 18a40 40 0 01-2 18c-2 4-5 7-10 6h-6c-2-2-1-5-1-7l-1-23v23c0 2 0 5-2 7z"
        ></path>
        <path fill="#8192d9" d="M1021 1082v-30c0-2-1-5 2-5s2 3 2 5v62c0 2 1 5-2 5s-2-3-2-5v-32z"></path>
        <path
          fill="#b8e2cc"
          d="M1028 1056c-1-13 1-25 3-38 3-7 4-15 8-22a56 56 0 014-7c5-8 7-8 14-2a99 99 0 0112 34l1 2c3 3 3 7 2 10v17c0 11-4 16-16 16h-4c-3-3-2-7-2-11v-20c-2 9 3 20-3 31h-4c-7 0-13-3-15-10z"
        ></path>
        <path
          fill="#828fdc"
          d="M1047 1066v-25a51 51 0 011-6 2 2 0 011-2c2-1 3 1 3 2v56a32 32 0 010 6 2 2 0 01-2 1h-1l-1-1a37 37 0 01-1-6v-25z"
        ></path>
        <path
          fill="#b8e2cc"
          d="M352 1054c-1-14 1-28 3-42 4-8 5-17 9-25a63 63 0 014-8c6-9 9-9 17-2 6 12 11 25 13 38l1 3c3 3 3 7 2 11v18c0 13-5 18-17 18h-5c-3-3-2-8-3-12v-23c-1 11 4 23-3 35h-4c-9 1-14-3-17-11z"
        ></path>
        <path
          fill="#828fdc"
          d="M373 1065l1-28a60 60 0 010-6 3 3 0 012-3c2 0 3 1 3 3v62a37 37 0 010 6 2 2 0 01-3 2l-2-2a43 43 0 010-6l-1-28z"
        ></path>
        <path
          fill="#bee5ce"
          d="M319 1106c-7-1-14 0-21-2-13-3-19-12-22-23-6-22-2-43 4-64l2-5 11-17c16-20 43-21 60-2a41 41 0 019 13 22 22 0 003 3c5 18 10 37 8 57a85 85 0 010 8c-5 24-14 32-38 32h-7c-7-7-3-15-5-22-2 7 2 15-5 22z"
        ></path>
        <path fill="#818fdb" d="M319 1106v-27c0-3 0-6 4-6 3 0 4 3 4 6l1 27-1 35c0 3 0 7-4 7-4-1-4-4-4-7v-35z"></path>
        <path
          fill="#f2aa79"
          d="M2930 1111a25 25 0 00-3 0c-18 2-23-9-24-24-1-10-2-20 1-30l1-8c3-23 6-47 14-70 2-6 4-13 9-18 5-6 8-6 13 0s7 12 9 19c5 16 8 32 11 48l2 12 3 30a67 67 0 01-4 30c-2 8-8 11-16 11h-9c-3-4-2-8-2-13l-1-38c-2 13-1 25-1 38 0 5 0 9-3 13z"
        ></path>
        <path fill="#818fdb" d="M2930 1111v-51c0-3-1-8 4-8 4 0 3 5 3 8v103c0 4 1 9-4 9-4-1-3-6-3-9v-52z"></path>
      </g>
      <path
        class="cloud"
        id="factoryscape-cloud-one"
        fill="#fff"
        d="M649 766l7 4c7 3 9 8 8 14 0 2-3 4-5 4H367c-7 0-12-1-15-7-13-21 0-42 27-47 12-2 12-2 13-12s2-21 11-29c14-11 30-11 46-5 4 1 8 4 10-2 6-19 24-27 42-34 21-9 60 1 74 23a128 128 0 0110 18c2 6 6 7 12 5 12-2 23 2 26 10 1 1 2 4 1 5-3 6-1 7 6 8 26 2 37 27 22 40-2 1-2 3-3 5z"
        opacity=".8"
        style="isolation: isolate"
      ></path>
      <path
        class="cloud"
        id="factoryscape-cloud-two"
        fill="#fff"
        d="M1780 912h-106c-8 0-8 2-7-6 0-5 3-7 8-8s6-1 7-6c1-6 0-11 3-16 4-7 9-11 17-11 4-1 7 0 11 1 6 1 6 1 6-4 0-9 5-18 12-25s15-13 26-15c12-2 23 1 32 7 10 8 16 17 20 28 2 5 2 5 7 2 8-4 16-4 24-3 15 3 24 11 29 23a21 21 0 012 5c0 2 1 3 4 3a47 47 0 018 2c8 1 11 6 12 12v9c1 2 0 2-2 2l-6 1h-107z"
        opacity=".8"
        style="isolation: isolate"
      ></path>
      <g class="stars-one" fill="#f9f458">
        <path
          d="M485 147v-1a36 36 0 0011-6 20 20 0 006-8 58 58 0 003-10l1-7v2a116 116 0 004 15l1 3a11 11 0 003 4 53 53 0 008 5l4 2 1 1-3 1a40 40 0 00-10 6 16 16 0 00-3 4 23 23 0 00-2 5 94 94 0 00-3 11v4h-1a140 140 0 00-3-15l-2-5a13 13 0 00-4-5 35 35 0 00-8-5l-3-1zM2730 178a36 36 0 0011-6 20 20 0 006-9 58 58 0 003-9l1-7v2a116 116 0 004 15l1 2a11 11 0 004 4 53 53 0 008 6l4 2-3 2a39 39 0 00-9 6 16 16 0 00-3 3 23 23 0 00-3 5 96 96 0 00-2 11l-1 5v-1a143 143 0 00-4-14l-2-5a13 13 0 00-4-6 35 35 0 00-8-5l-3-1z"
        ></path>
      </g>
      <g class="stars-two" fill="#f9f458">
        <path
          d="M1709 694h1a36 36 0 0010-6 20 20 0 006-9 58 58 0 003-10l1-6v-1l1 3a116 116 0 004 14l1 3a11 11 0 003 4 53 53 0 008 5l4 3-3 1a39 39 0 00-9 6 16 16 0 00-3 4 24 24 0 00-2 5 96 96 0 00-3 11l-1 4v1-1a143 143 0 00-4-15l-1-5a13 13 0 00-4-5 35 35 0 00-9-5l-3-1zM2507 31a36 36 0 0011-6 20 20 0 006-8 58 58 0 003-10l1-7 1 2a115 115 0 004 15l1 2a11 11 0 003 5 53 53 0 008 5l4 2-3 2a40 40 0 00-9 6 16 16 0 00-3 3 24 24 0 00-2 6 94 94 0 00-3 11l-1 4v-1a143 143 0 00-4-14l-1-5a13 13 0 00-4-6 35 35 0 00-9-4l-3-2z"
        ></path>
      </g>
      <g class="stars-three" fill="#f9f458">
        <path
          d="M170 535h1a36 36 0 0011-6 20 20 0 006-9 58 58 0 002-10l1-6 1 2a115 115 0 004 15l1 2a11 11 0 003 4 53 53 0 008 6l4 2-3 1a40 40 0 00-9 6 16 16 0 00-3 4 24 24 0 00-2 5 94 94 0 00-3 11l-1 5v-1a143 143 0 00-3-14l-2-6a13 13 0 00-4-5 35 35 0 00-8-5l-3-1h-1zM2323 529l1-1a36 36 0 0011-6 20 20 0 006-8 58 58 0 002-10l1-6v-1l1 2a115 115 0 004 15l1 3a11 11 0 003 4 53 53 0 008 5l4 2v1l-3 1a39 39 0 00-9 6 16 16 0 00-3 4 24 24 0 00-2 5 94 94 0 00-3 11l-1 4a143 143 0 00-3-15l-2-5a13 13 0 00-4-5 36 36 0 00-8-5l-3-1h-1z"
        ></path>
      </g>
    </svg>

    <div class="smoke smoke-one">
      <span class="s0"></span>
      <span class="s1"></span>
      <span class="s2"></span>
      <span class="s3"></span>
      <span class="s4"></span>
      <span class="s5"></span>
      <span class="s6"></span>
      <span class="s7"></span>
      <span class="s8"></span>
      <span class="s9"></span>
    </div>

    <div class="smoke smoke-two">
      <span class="s0"></span>
      <span class="s1"></span>
      <span class="s2"></span>
      <span class="s3"></span>
      <span class="s4"></span>
      <span class="s5"></span>
      <span class="s6"></span>
      <span class="s7"></span>
      <span class="s8"></span>
      <span class="s9"></span>
    </div>

    <div class="smoke smoke-three">
      <span class="s0"></span>
      <span class="s1"></span>
      <span class="s2"></span>
      <span class="s3"></span>
      <span class="s4"></span>
      <span class="s5"></span>
      <span class="s6"></span>
      <span class="s7"></span>
      <span class="s8"></span>
      <span class="s9"></span>
    </div>
  </section>
</template>

<script>
import Para from '@/components/Para.vue';
import SectionHeader from '@/components/SectionHeader.vue';

export default {
  name: 'about',
  components: {
    SectionHeader,
    Para,
  },
};
</script>

<style scoped>
#about {
  position: relative;
  padding-top: 30px;
  padding-bottom: 180px;
}

.about-content {
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
  margin-top: 30px;
}

.about-content-main {
  width: 60%;
}

.about-content-aside {
  position: relative;
  z-index: 1;
  width: 32%;
}

p + p {
  margin-top: 40px;
}

.aside-number {
  font-family: var(--font-secondary), sans-serif;
  font-size: 80px;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--color-accent);
}

.aside-refer {
  font-family: var(--font-primary), sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  margin-top: -15px;
  color: var(--color-regular-text);
}

.aside-block + .aside-block {
  margin-top: 20px;
}

#factoryscape {
  position: absolute;
  bottom: 0;
}

#factoryscape-cloud-one {
  animation: cloud-animation-one 6s linear infinite alternate;
}

#factoryscape-cloud-two {
  animation: cloud-animation-two 12s linear infinite alternate;
}

@keyframes cloud-animation-one {
  100% {
    transform: translateX(-8vw);
  }
}

@keyframes cloud-animation-two {
  100% {
    transform: translateX(24vw);
  }
}

/* Smoke container */

.smoke {
  position: absolute;
  width: 1px;
  height: 160px;
}

.smoke-one {
  right: 6%;
  bottom: 14vw;
}

.smoke-two {
  right: 12%;
  bottom: 14vw;
}

.smoke-three {
  right: 18%;
  bottom: 14vw;
}

/* smoke balls */

.smoke span {
  display: block;
  position: absolute;
  bottom: -35px;
  left: 50%;
  margin-left: -20px;
  height: 0px;
  width: 0px;
  border: 35px solid #4b4b4b;
  border-radius: 35px;
  left: -14px;
  opacity: 0;
  transform: scale(0.2);
}

/* Smoke animation */

@keyframes smokeL {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(-20px, -130px);
  }
}

@keyframes smokeR {
  0% {
    transform: scale(0.2) translate(0, 0);
  }
  10% {
    opacity: 1;
    transform: scale(0.2) translate(0, -5px);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(20px, -130px);
  }
}

.smoke .s0 {
  animation: smokeL 10s 0s infinite;
}
.smoke .s1 {
  animation: smokeR 10s 1s infinite;
}
.smoke .s2 {
  animation: smokeL 10s 2s infinite;
}
.smoke .s3 {
  animation: smokeR 10s 3s infinite;
}
.smoke .s4 {
  animation: smokeL 10s 4s infinite;
}
.smoke .s5 {
  animation: smokeR 10s 5s infinite;
}
.smoke .s6 {
  animation: smokeL 10s 6s infinite;
}
.smoke .s7 {
  animation: smokeR 10s 7s infinite;
}
.smoke .s8 {
  animation: smokeL 10s 8s infinite;
}
.smoke .s9 {
  animation: smokeR 10s 9s infinite;
}

@media (--desktop-narrow) {
  .about-content {
    padding: 0 100px;
  }
}

@media (--mobile-wide) {
  .about-content {
    flex-direction: column;
  }

  .about-content-main {
    width: 100%;
    margin-bottom: 30px;
  }

  .about-content-aside {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .aside-block + .aside-block {
    margin-top: 0px;
    /* margin-left: 20px; */
  }

  .aside-block {
    text-align: center;
  }

  .aside-number {
    font-size: calc(10px + 10.5vw);
  }

  .aside-refer {
    font-size: calc(8px + 1.5vw);
  }
}

@media (--mobile-narrow) {
  #about {
    padding-bottom: 100px;
  }

  .about-content {
    padding: 0 30px;
  }
}
</style>

import { render, staticRenderFns } from "./TNCModal.vue?vue&type=template&id=0c269740&scoped=true&"
import script from "./TNCModal.vue?vue&type=script&lang=js&"
export * from "./TNCModal.vue?vue&type=script&lang=js&"
import style0 from "./TNCModal.vue?vue&type=style&index=0&id=0c269740&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c269740",
  null
  
)

export default component.exports
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"contact","title":"Contact Us"}},[_c('div',{staticClass:"contact-body"},[_c('SectionHeader',[_vm._v("Contact Us")]),_c('form',{ref:"form",staticClass:"contact-content",attrs:{"id":"contact-form","name":"contact-form"},on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('FormInput',{attrs:{"type":"text","label":"*Name","name":"name","placeholder":"Your cool name :)","model":_vm.contacteeName,"onBlur":_vm.validateFilled}}),_c('FormInput',{attrs:{"type":"text","label":"*Email Address","name":"email","placeholder":"No spam, promise!","model":_vm.contacteeEmail,"onBlur":function (s) {
            if (!_vm.validateFilled(s)) {
              _vm.validateEmail(s);
            }
          }}}),_c('Textbox',{attrs:{"label":"*What would you like to talk to us about?","name":"message","model":_vm.contacteeMessage,"onBlur":_vm.validateFilled,"placeholder":"Anything is fine! Your questions, feedback, suggestions, etc."}}),_c('button',{staticClass:"form-button",attrs:{"type":"submit"}},[_vm._v("Submit")])],1),_c('div',{staticClass:"submission-container"},[_c('FormError',{staticClass:"submission-error",style:(_vm.submissionMsg.type === 'positive' ? 'color: var(--color-accent);' : null)},[_vm._v(_vm._s(_vm.submissionMsg.msg))]),_c('button',{attrs:{"type":"submit","value":"Send"}})],1)],1),_c('svg',{staticStyle:{"transform":"translateY(-7px)"},attrs:{"viewBox":"0 0 1440 320","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"slope",attrs:{"d":"m0 128 48 16c48 16 144 48 240 75 96 26 192 48 288 48s192-22 288-22 192 22 288 38 192 26 240 32l48 5v-320h-1440z"}})]),_c('ConfirmationModal',{attrs:{"id":"contact-submission-confirmation-modal","positiveFunc":function () {
        if (!_vm.validateAll()) {
          _vm.submitForm('contact-form');
        }
      }}},[_vm._v("Are you sure you want to submit the form?")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ContentModal :id="id">
    <h3>Terms and Conditions</h3>
    <h4>1. Definitions</h4>
    <Para>
      <b>“Competition”</b> refers to the What The Hack @ SUTD Hackathon.
      <br />
      <br />
      <b>“Organiser”</b> refers to the Singapore University of Technology and Design (SUTD) as represented by the What
      The Hack Organising Committee.
      <br />
      <br />
      <b>“Participant”</b> refers to each member of a team which enters and takes part in the Competition.
      <br />
      <br />
      <b>“Sponsors”</b> refers to parties that have provided monetary or non-monetary assistance to the hackathon in
      exchange for sponsorship benefits.
      <br />
      <br />
      <b>“Problem Themes”</b> refers to the problem statements of Built Environment, Natural Environment, Waste
      Management, and Transportation, set out in Clause 4.b. below.
      <br />
      <br />
      <b>“Technology Categories”</b> refers to the types of technologies set out in Clause 4.b. below.
      <br />
      <br />
    </Para>

    <h4>2. Introduction</h4>
    <Para>
      a. “What The Hack @ SUTD”, is a 24-hour hackathon organised by SUTD and co-sponsored by Indeed, GovTech and other
      sponsors. The Competition aims to bring together people of multiple disciplines to challenge them to solve
      imminent problems of the future via interdisciplinary collaborations.
      <br />
      <br />b. The Competition will be held at SUTD from 18th September 2022 to 19th September 2022 (24 hours).
      <br />
      <br />c. Participants who are students at SUTD will be allowed to go to SUTD campus for the Competition, with
      proper safety measures in place.
      <br />
      <br />
    </Para>

    <h4>3. Eligibility and Teams</h4>
    <Para>
      a. This Competition is open to Participants of all nationalities who are at least 13 years old (as of 18th
      September 2022).
      <br />
      <br />b. The Participants may compete in teams of 3 to 5 members.
      <br />
      <br />c. Participants who are below 18 years old as at the date of the Competition must have proper
      parental/guardian’s consent to participate in the Competition. The parental/guardian consent form is provided
      along with the online registration form. Participants under 18 years old will be automatically disqualified from
      the Competition if proper consent has not been obtained and submitted before the start of the Competition.
      <br />
      <br />
    </Para>

    <h4>4. Submissions and Demonstration</h4>
    <Para>
      a. "Submission" refers to the prototype of the product or service created by participants and any related
      materials that is presented to the judges.
      <br />
      <br />b. The Participants, as a team, will be required to work on building solutions to problems broadly
      categorised according to the themes of Built Environment, Natural Environment, Waste Management, and
      Transportation. The Participants are to create solutions for their Submission for the Competition using at least
      one of the technologies in the following broad technology categories: Platforms and Applications, Internet of
      Things, Data Science / Artificial Intelligence, and Mechanical Design / Digital Fabrication / Robotics, which are
      to be submitted for judging by a panel of judges.
      <br />
      <br />c. The Participants may only begin working (coding, building, analysing) on their Submissions at the
      official commencement time fixed by the Organiser and must complete their Submission by the end of the 24 hours
      after the official commencement time.
      <br />
      <br />d. Each Submission must:
      <br />
      <br />i. be submitted to the platform that the Organiser has provided to count as a valid submission;
      <br />
      <br />ii. adhere to these Terms and Conditions and any rules and regulations of the Competition set out at the
      beginning of the Hackathon;
      <br />
      <br />iii. not breach the terms and conditions of any embedded software or services used in the Submission;
      <br />
      <br />iv. not contain any malicious code or backdoors;
      <br />
      <br />v. not contain, depict or refer to any crude, vulgar, obscene, sexually explicit, disparaging,
      discriminatory, offensive, illegal or otherwise unsuitable or inappropriate language, activity or other content
      (as determined by the Organiser at its sole discretion);
      <br />
      <br />vi. not contain, depict or refer to any content which disparages or puts the Organiser, Sponsors, the
      Competition or any other person or entity in a negative light (as determined by the Organiser’s in its sole
      discretion); and
      <br />
      <br />vii. not contain any content that violates any law or any third party’s rights, including but not limited to
      any intellectual property rights, privacy, personal data protection and intellectual property rights.
      <br />
      <br />
    </Para>

    <h4>5. Judging</h4>
    <Para>
      a. At the end of the 24-hour hacking period, the Participants will present their Submissions through a platform
      that is to be determined by the Organisers. Each Participant or Team with a Submission will be given up to 20
      minutes (any request for additional time may be granted at the sole discretion of the Organiser) to set up their
      virtual presentation booths , and four (4) minutes to present his/her/their Submission to a panel of judges. The
      order of the Participants’ or teams’ presentations will be determined by the Organiser.
      <br />
      <br />b. Participants are to explain and/or demonstrate how well their Submissions fulfil the following equally
      weighted judging criteria:
      <br />
      <br />i. Creativity – How unique or interesting the idea is;
      <br />
      <br />ii. Execution – How well the idea has been pitched and displayed at the virtual booth;
      <br />
      <br />iii. Scalability – How economically sustainable the idea is; and
      <br />
      <br />iv. Environmental Impact - How the idea impacts the environment.
      <br />
      <br />c. The judging panel for each Problem Theme will be announced at the start of the competition.
      <br />
      <br />
    </Para>

    <h4>6. Disqualification</h4>
    <Para>
      a. A Participant or Team’s Submission may be disqualified if the Organiser, in its sole discretion, reasonably
      finds or believes that the Submission:
      <br />
      <br />i. contains any element that is malicious, corrupt, damaged, incomplete, inappropriate or offensive;
      <br />
      <br />ii. violates the terms of use of any social media, website, mobile application or any other platform used in
      the Submission;
      <br />
      <br />iii. infringes any third party’s intellectual property rights;
      <br />
      <br />iv. breaches or violates any applicable law; or
      <br />
      <br />v. breaches or violates any of these Terms and Conditions or any rules or regulations set out by the
      Organiser of the Competition.
      <br />
      <br />b. Further, a Participant and/or his/her entire Team may be immediately disqualified, with or without
      warning at the Organiser’s sole discretion, for:
      <br />
      <br />i. verbal abuse of another participant or the Organiser’s representatives, agents or employees;
      <br />
      <br />ii. deliberate and/or inappropriate physical contact, or harm or injury to another Participant or the
      Organiser’s or the corporate Sponsors’ representatives agents or employees;
      <br />
      <br />iii. any breach of the safety rules or directives issued by the Organiser and/or its employees in relation
      to SUTD’s Fabrication Laboratory or other premises or facilities (only applicable to SUTD Participants who are
      attending the hackathon physically); or
      <br />
      <br />iv. any attempt to compromise the integrity or the legitimate operation of the Competition, including,
      without limitation, by cheating, hacking, creating a malicious bot or any other automated program, or by
      committing fraud in any way.
      <br />
      <br />c. If, during the Competition, any of the Organiser’s representatives, agents or employees reasonably
      believes that a Participant’s health is in danger, the Participant may be required to discontinue his/her
      participation in the Competition and asked to seek immediate medical assistance. The Participant’s teammates, if
      any, may continue participating in the Competition if they so choose.
      <br />
      <br />d. All disqualification decisions are final and not subject to appeal.
      <br />
      <br />
    </Para>

    <h4>7. Prizes</h4>
    <Para>
      a. The winner of each Problem Theme will be awarded the respective titles:
      <br />
      <br />i. Best Built Environment Hack
      <br />
      <br />ii. Best Natural Environment Hack
      <br />
      <br />iii. Best Waste Management Hack
      <br />
      <br />iv. Best Transportation Hack
      <br />
      <br />b. In addition to the Problem Theme winners, Prizes will also be awarded to winners of the following
      categories:
      <br />
      <br />i. Best Hardware Hack
      <br />
      <br />ii. Best Design Hack
      <br />
      <br />iii. Most Green Hack
      <br />
      <br />c. The Organiser and Sponsors reserve the right to withhold the Prize from a Participant or Team even after
      he/she/it has been announced as a winner or to require a Participant or Team to return the Prize after it has been
      awarded if the Participant or Team is found to have breached any of these Terms and Conditions in the course of
      the Competition.
      <br />
      <br />d. Participants acknowledge that the prizes and judging procedures stated in these Terms and Conditions or
      on the Competition website or in any announcements or notifications to the Participants are subject to change at
      the Organiser’s sole discretion.
      <br />
      <br />e. Prizes must be collected in person by the winning Participant or team or a member of the winning Team.
      All taxes and other expenses, costs or fees associated with the acceptance and/or use of any Prize are the sole
      responsibility of the winning Participant or team members. If a Prize is unclaimed within 10 business days after
      notification, the Organiser may at its sole discretion forfeit the Prize and choose an alternate winner to be
      selected from the remaining eligible Submissions at its discretion.
      <br />
      <br />
    </Para>

    <h4>8. Other Terms and Conditions</h4>
    <Para>
      By entering and participating in the Competition, every Participant is deemed to have accepted and agreed to abide
      by these Terms and Conditions.
      <br />
      <br />b. The Organiser reserves the right in its sole discretion to cancel, suspend or postpone the Competition
      due to events beyond its reasonable control which does not allow for the commencement or continuation of the
      Competition.
      <br />
      <br />c. The Organiser reserves the right to amend these Terms and Conditions at any time without prior notice.
      <br />
      <br />d. The Organiser reserves the right to reject any of the Participants’ submissions for the Competition or to
      disallow any Participant’s or Team’s continued participation in the Competition if the Participant and/or Team
      is/are found to have breached any of these Terms and Conditions or any rules or regulations set out by the
      Organiser for the Competition.
      <br />
      <br />e. In the event of any inconsistency between these Terms and Conditions and any brochure, website, marketing
      or promotional material relating to the Competition or any rules or regulations set out by the Organiser, these
      Terms and Conditions, and all its subsequent variations, shall prevail.
      <br />
      <br />
    </Para>

    <h4>9. Intellectual Property</h4>
    <Para>
      a. Participants represent and warrant that their Submission(s) is/are their original work/creation and does/do not
      infringe the intellectual property rights belonging to any third party. The Participant agrees to indemnify the
      Organiser and each of the corporate Sponsors against any claim, damages, debt, expense, liability, loss, suit,
      action, demand, cause of action, proceeding or judgment of any kind which are made or brought against or suffered
      or incurred by each of them and arise directly and/or indirectly out of or in connection with any infringement of
      intellectual property rights by virtue of the work submitted by the Participant for the Competition.
      <br />
      <br />b. Participants own all rights to the Intellectual Property produced by them or their team during the
      Competition. Participants may use any freely available data, API or Technology (which may include but is not
      limited to content, code, algorithm, design, art, music, graphics or material otherwise protected by any
      intellectual property rights) to build their prototype or material for their Submission. Participants may use any
      tools they own as well, physical or otherwise. This means any Technology that a Participant or a member of the
      team owns, acquired the rights to or owns license to may be used.
      <br />
      <br />c. Each Participant hereby grants to the Organiser a non-exclusive, perpetual, irrevocable, worldwide,
      royalty-free right and license to use any intellectual property rights comprised in his/her Submission for the
      purpose of publicity and marketing campaigns, branding, administrative functions of the Organiser and Sponsors and
      for the Organiser’s faculty, staff and student recruitment, education, teaching and research purposes.
      <br />
      <br />d. However, the Organiser shall not be obliged to use, publicise or otherwise communicate publicly the
      Participant’s work/creation submitted for the Competition.
      <br />
      <br />
    </Para>

    <h4>10. Personal Data</h4>
    <Para>
      a. By registering for and participating in the Competition, the Participant hereby consents to and authorises the
      Organiser to use and/or disclose to third parties his/her personal data, including photos, videos or audio
      recordings taken before, during or after the Competition, for the purposes of:
      <br />
      <br />i. organising the Competition; and
      <br />
      <br />ii. the Organiser’s and Sponsors’ publicity and marketing campaigns including but not limited to the
      Organiser’s and Sponsors’ publicity materials and/or publication on each of the Organiser’s and the Sponsors’
      website and/or the Competition website, as well as to showcase the Competition for future promotional purposes.*
      <br />
      <br />*Applicable only upon consent being given by the participant in the registration form
      <br />
      <br />
    </Para>

    <h4>11. Disclaimers</h4>
    <Para>
      a. The Participant agrees that the Organiser and the Sponsors shall not be liable for any death or personal injury
      suffered by the Participant as a result of or in connection with the Participant’s participation in the
      Competition provided the same is not caused by the negligence of the Organiser and Sponsors.
      <br />
      <br />b. The Participant agrees that the Sponsors and the Organiser shall not be liable and that he/she shall not
      hold the Sponsors or the Organiser liable for any property damage, claim, cost, damages, debt, expense, liability,
      loss, suit, action, demand, cause of action, proceeding or judgment of any kind which are made or brought against
      or suffered or incurred by the Participant including any indirect, special, consequential, or punitive damages
      which arise directly and/or indirectly out of or in connection with the Participant’s participation in the
      Competition.
      <br />
      <br />c. The Participant agrees that he/she, whether individually or jointly with his/her team members, shall be
      liable to the Organiser and/or Sponsors and their officers, employees and agents for any breach or violation of
      confidentiality, patent, copyright or any other intellectual property rights in his/her team’s Submission and to
      defend, indemnify and hold the Organiser and the Sponsors and each of their officers, employees and agents
      harmless from and against any actions, claims, loss or damages for infringement of alleged infringement of such
      confidentiality, patent, copyright or any other intellectual property rights.
      <br />
      <br />d. The Participants agree to keep the Organiser and the Sponsors and their respective directors, officers,
      employees and agents indemnified against any and all such claims by third parties for any losses or damages
      arising from his/her Submission and/or participation in the Competition.
      <br />
      <br />
    </Para>

    <h4>12. Governing Law</h4>
    <Para>
      a. These Terms and Conditions shall be subject to, governed by and interpreted in accordance with the Laws of the
      Republic of Singapore for every purpose and the Participant agrees that the Courts of Singapore shall have
      exclusive jurisdiction to determine any dispute or claim arising from or in connection with these Terms and
      Conditions and/or the Competition.
    </Para>
  </ContentModal>
</template>

<script>
import ContentModal from '@/components/ContentModal.vue';
import Para from '@/components/Para.vue';

export default {
  name: 'tnc-modal',
  components: {
    ContentModal,
    Para,
  },
  props: {
    id: String,
  },
};
</script>

<style scoped>
h3 {
  font-size: 24px;
  font-family: var(--font-primary), sans-serif;
  color: var(--color-regular-text);
  margin-bottom: 30px;
  text-align: center;
  text-decoration: underline;
}

h4 {
  font-size: 24px;
  font-family: var(--font-primary), sans-serif;
  color: var(--color-regular-text);
  margin-bottom: 30px;
  text-decoration: underline;
}

@media (--mobile-narrow) {
  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }
}
</style>
